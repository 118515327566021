<template>
  <div class="d-inline-flex align-center">
    <div>{{ titleText }}</div>
    <div class="ml-1">
      <v-menu v-model="isMenuOpened" :close-on-content-click="false" :min-width="MENU_MIN_WIDTH" :max-width="MENU_MAX_WIDTH">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon :color="isFilterApplied ? 'warning' : ''"> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <LogsFilter
                :label="titleText"
                :item-value="itemValue"
                :item-text="itemText"
                :values="selected"
                :options="items"
                :loading="isOptionsLoading"
                :checkbox-group-data="checkboxGroupData"
                :no-filter="noFilter"
                v-bind="$attrs"
                @select="select"
                @input="(value) => (searchInput = value)"
                @ok="handleOk"
                @clear="handleClear"
                @checkbox="handleCheckbox"
              >
                <template v-slot:item="{ item }">
                  <slot name="item" :item="item"></slot>
                </template>
                <template v-slot:selection="{ item }">
                  <slot name="selection" :item="item"></slot>
                </template>
                <template v-slot:append-item>
                  <div
                    v-intersect.quiet="{
                      handler: onIntersect,
                      options: {
                        threshold: 1,
                      },
                    }"
                    class="text-center grey--text"
                  >
                    <span v-show="isOptionsLoading">Завантаження ...</span>
                  </div>
                </template>
              </LogsFilter>
            </v-list-item>
          </v-list>
          <template v-slot:checkbox-group>
            <slot name="checkbox-group"></slot>
          </template>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import LogsFilter from '@/components/common/filters/TableTitleFilter/LogsFilter'

export default {
  name: 'TableTitleFilter',
  components: { LogsFilter },

  emits: ['ok', 'select', 'checkbox'],

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    titleText: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'label',
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionsMeta: {
      type: Object,
      default: () => null,
    },
    optionsParams: {
      type: Object,
      default: () => null,
    },
    checkboxGroupData: {
      type: Object,
      default: () => null,
    },
    noFilter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      MENU_MIN_WIDTH: 450,
      MENU_MAX_WIDTH: 450,
      isMenuOpened: false,
      payload: null,
      items: [],
      selected: [],
      selectedCheckboxes: [],
      searchInput: null,
      isOptionsLoading: false,
      page: 0,
      meta: null,
    }
  },

  mounted() {
    this.initialize()
  },

  watch: {
    isMenuOpened(val) {
      if (val && !this.selected.length && !this.selectedCheckboxes.length) {
        this.page = 0
        this.paginateNext()
      } else {
        this.searchInput = null
      }
    },

    values(val) {
      this.select(val)
      for (const value of this.values) {
        if (!this.items.find((item) => item[this.itemValue] === value[this.itemValue])) {
          this.items.push(value)
        }
      }
    },

    options(val) {
      if (!this.optionsMeta) this.items = [...val]
    },

    async searchInput(val) {
      this.items = []
      if (this.optionsParams) {
        this.page = 0
        this.payload = {
          ...this.payload,
          search: val,
        }
        await this.paginateNext()
      } else {
        this.items = [...this.options.filter((item) => item[this.itemText].toString().toUpperCase().includes(val.toUpperCase()))]
      }
    },
  },

  computed: {
    isFilterApplied() {
      return !!this.selected.length || !!this.selectedCheckboxes.length
    },
  },

  methods: {
    async initialize() {
      if (this.optionsParams) {
        this.payload = this.optionsParams.payload
      } else {
        this.items = [...this.options]
        this.selected = [...this.values]
      }
    },

    async paginateNext() {
      if (this.optionsParams) {
        this.isOptionsLoading = true
        this.payload = {
          ...this.payload,
          page: ++this.page,
          search: this.searchInput,
        }
        await this.optionsParams.loadingFunction(this.payload)
        this.$set(this.items, this.items.length, this.options)
        this.items = this.items.flat()
        if (this.optionsMeta) {
          this.meta = { ...this.optionsMeta }
        }
        this.isOptionsLoading = false
      } else {
        this.items = [...this.options]
      }
    },

    select(values) {
      this.selected = values
      this.$emit('select', this.selected)
    },

    handleOk(selected) {
      this.select(selected)
      this.isMenuOpened = false
      this.$emit('ok', selected)
    },

    handleClear() {
      this.items = []
      this.selected = []
      this.selectedCheckboxes = []
      this.select([])
      this.isMenuOpened = false
      this.$emit('checkbox', [])
      this.$emit('ok', [])
    },

    handleCheckbox(values) {
      this.selectedCheckboxes = values.flat()
      this.$emit('checkbox', this.selectedCheckboxes)
    },

    async onIntersect(entries) {
      if (
        this.optionsParams &&
        this.optionsMeta &&
        entries[0].intersectionRatio === 1 &&
        entries[0].isIntersecting &&
        this.page < this.meta.total_pages
      ) {
        await this.paginateNext()
      }
    },
  },
}
</script>
